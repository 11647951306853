import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  signinForm: FormGroup;

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    public firestore: AngularFirestore,
  ) { }

  ngOnInit() {
    this.signinForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl('')
    });
  }

  async onSignin2() {
    const data = this.signinForm.value;

    this.authenticationService.signin2(data.email, data.password)
    .subscribe (
      res => {
        // if signin success then:

        console.log('4')
        console.log(this.authenticationService.userUid)
        this.firestore.doc('users/'+this.authenticationService.userUid).get().forEach(e => {
          return new Promise<any>((resolve, reject) => {
            console.log('1')
            resolve(res)
          }).then(e2 => {
            console.log('2')
            if (e.data().isAdmin == true) {
              this.router.navigate(['dashboards/ecommerce']);
            } else {
              alert('User is not an admin')
              this.authenticationService.logout()
            }
          })
        })
      },
      err => {
        // else if signin fails
        // show error
        alert('Could not login!')
      }
    );
  }

  async onSignin() {
    const data = this.signinForm.value;

    this.authenticationService.signin(data.email, data.password).then(e => {
      this.firestore.doc('users/'+this.authenticationService.userUid).get().forEach(e2 => {
        return new Promise<any>((resolve, reject) => {
          console.log('1')
          resolve()
        }).then(e3 => {
          console.log('2')
          if (e2.data().isAdmin == true) {
            console.log(this.authenticationService.loggedUser)
            //this.firestore.doc('users/'+this.authenticationService.userUid).get().forEach(e3 => {
              //this.userUid = e3.data().uid
              //console.log(e3.data())
              //this.authenticationService.setUser(e3.data().name, e3.data().profile, e3.data().email)
            //})
            this.router.navigate(['dashboards/ecommerce']);
          } else {
            alert('User is not an admin')
            this.authenticationService.logout()
          }
        })
      })
    })
  }


}
