import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, } from 'rxjs';
import { LoggedUserModel } from './logged-user.model';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {

  userUid = ''
  public currentUser = {
    name: '',
    lastname: '',
    image: '',
    email: '',
  }

  loggedUserSubject: BehaviorSubject<LoggedUserModel>;
  loggedUser: LoggedUserModel;

  constructor(
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore
  ) {
    this.loggedUserSubject = new BehaviorSubject(this.loggedUser);
  }

  isAuthenticated() {
    return this.loggedUserSubject.asObservable();
  }

  // tslint:disable-next-line:max-line-length
  //setUser(name: string = 'Pete', lastname: string = 'Amer', image: string = '/assets/imgs/users/menoclasses.jpg', email: string = 'pete@amers.co.uk') {
  setUser(name: string, image: string, email: string) {
    if (image == undefined) {
      image = 'https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/7_avatar-512.png'
    }
    // this sets a default user for the template
    console.log(name, image, email)
    this.loggedUser = new LoggedUserModel();
    this.loggedUser.name = name;
    this.loggedUser.lastname = '';
    this.loggedUser.image = image;
    this.loggedUser.email = email;
  }

  signin2(email: string, password: string) {
    console.log(this.currentUser)

    new Promise<any>((resolve, reject) => {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(
        res => (resolve(res), console.log(res.user.uid), this.userUid = res.user.uid),
        err => reject(err))
    }).then(e2 => {
      console.log('3')
      this.loggedUserSubject.next(this.loggedUser);

      /*
      this.firestore.doc('users/'+this.userUid).get().forEach(e3 => {
        //this.userUid = e3.data().uid
        this.setUser(e3.data().name, e3.data().profile, e3.data().email)
      })
      */
      
      
      //this.setUser(this.currentUser.forename, this.currentUser.surname, this.currentUser.image, this.currentUser.email);
      //this.loginUser(email, password);
      // your log in logic should go here
    })
    return of(true);

    
    
  };

  signin(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(
        res => (resolve(res), 
        console.log(res.user.uid), 
        this.userUid = res.user.uid,
        this.firestore.doc('users/'+res.user.uid).get().forEach(e3 => {
          //this.userUid = e3.data().uid
          console.log(e3.data())
          this.setUser(e3.data().name, e3.data().profile, e3.data().email)
          this.loggedUserSubject.next(this.loggedUser);
        })),
        err => reject(err))
    })
  }

  signup(name: string, lastname: string, email: string, password: string): Observable<any> {
    //this.setUser(this.currentUser.forename, this.currentUser.surname, this.currentUser.image, this.currentUser.email);
    // your signup logic should go here
    this.loggedUserSubject.next(this.loggedUser);
    return of(true);
  }

  loginUser(email, password) {
    console.log(this.currentUser)
    return new Promise<any>((resolve, reject) => {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(
        res => resolve(res),
        err => reject(err))
    })
  }

  userDetails() {
    return this.afAuth.auth.currentUser
  }

  logout(): Observable<any> {
    this.loggedUser = null;
    // your log out logic should go here
    this.loggedUserSubject.next(null);
    this.afAuth.auth.signOut()
    return of(true);
  }

  recoverPassword(email: any): Observable<any> {
    //this.setUser(this.currentUser.forename, this.currentUser.surname, this.currentUser.image, this.currentUser.email);
    // your recover password login should go here
    this.loggedUserSubject.next(this.loggedUser);
    return of(true);
  }

}
